import * as React from "react"
import { useEffect } from 'react';
import { navigate } from "@reach/router"

import "../styles/404.less"

const NotFoundPage = () => {
  useEffect(() => {
    navigate('/interior-design-portfolio/');
  }, []);

  return (
    <div class="not-found">
      <div class="not-found">
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </div>
  )
}

export default NotFoundPage
